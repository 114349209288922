import * as React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo title="All posts" />
        <Bio />
        <p>
          No people found.
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="The 'Patriot' Party" />
      <Bio />
      <ol style={{ listStyle: `none` }}>
        {posts.map(post => {
          const title = post.frontmatter.title || post.fields.slug
          const date_of_death = post.frontmatter.date_of_death === 'January 01, 2000' ? '?' : post.frontmatter.date_of_death
          const image = getImage(post.frontmatter.image)

          return (
            <li key={post.fields.slug}>
              <article
                className="post-list-item"
                itemScope
                itemType="http://schema.org/Article"
              >
                <header className="centered">
                  <GatsbyImage 
                    image={image}
                    alt={title}
                  />
                  <h2>
                    <span itemProp="headline">{title}</span>
                  </h2>
                  <small>{post.frontmatter.date_of_birth} - {date_of_death}</small>
                </header>
              </article>
            </li>
          )
        })}
      </ol>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date_of_birth], order: ASC }) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          date_of_birth(formatString: "MMMM DD, YYYY")
          date_of_death(formatString: "MMMM DD, YYYY")
          title
          image {
            childImageSharp {
              gatsbyImageData(
                width: 200
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  }
`
